import axios from "axios";
import { useState, useEffect } from "react";
import AuthService from "../services/AuthService";
import { Loading } from "../components/Loading";
import { HeaderTable } from "../components/HeaderTable";
import { Typography } from "@mui/material";
import DataService from "../services/DataService";
import { useNavigate } from "react-router-dom";


interface LoanProps {
    isMobile: boolean
    config: Config
}

declare global {
    type Loan = {
        name: string,
        value: number,
        signedDate: string,
        project: {name:string},
        payedDate: string,
        state: string
    }
}

export const Loan: React.FC<LoanProps> = (props) => {
    const navigate = useNavigate();
    const [finishedLoading, setFinishedLoading] = useState<{loan: boolean}>({loan: false});

    const [loanData, setLoanData] = useState<Loan[]>([{
        name: "Nicht vorhanden",
        value: 0.00,
        signedDate: "Nicht vorhanden",
        project: {name:"Nicht vorhanden"},
        payedDate: "Nicht vorhanden",
        state: "Unbekannt"
    }]);

    useEffect(() => {
        if (!AuthService.isLoggedIn()) {
            AuthService.doLogin();
        } else {
            DataService.performRequest(`/rest/services/beg_PortalLoanService/getLoans`,finishedLoading,setFinishedLoading,setLoanData,"loan", navigate)
        }
    }, []);
    

    if (!finishedLoading.loan) {
        return <Loading />
    }

    const to2dArray = (objectArray: any) => {
        let returnElement: any[] = [];
        objectArray.map((objectEl:Loan) => {
            returnElement.push([
                objectEl.name,
                objectEl.value.toLocaleString(undefined,{minimumFractionDigits: 2}) + " €",
                objectEl.project.name,
                getLoanState(objectEl.state),
                objectEl.signedDate ? new Date(objectEl.signedDate).toLocaleDateString() : "",
                objectEl.payedDate ? new Date(objectEl.payedDate).toLocaleDateString() : ""
             ]);
        })
        return returnElement;
    }

    const getLoanState = (state: string) => {
        //ist im System schon rechtschreibfehler, hab den nur übernommen (canceled)
        if (state === "CANCELED") return "Storniert"
        else if (state === "CLOSED") return "Geschlossen"
        else if (state === "DRAFT") return "Entwurf"
        else if (state === "INTENT") return "Absichtserklärung"
        else if (state === "PAYED") return "Bezahlt"
        else if (state === "SENT") return "Vertrag versendet"
        else if (state === "SIGNED") return "Unterschrieben"
        else return "Unbekannt"
    }
    
    return (
        <div className="content">
            <Typography variant="h6">Projektdarlehen</Typography>
            <HeaderTable headerRows={["Bezeichnung","Wert","Projektname","Darlehenstatus","Unterschrieben am","Ein-/Auszahlung am"]}
            rows={
                to2dArray(loanData)
            }
            />
        </div>
    )

}