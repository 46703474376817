import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { BrowserRouter, Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import { Navigation } from './components/Navigation';
import { Antrag } from './pages/Antrag';
import { Attachments } from './pages/Attachments';
import { Dashboard } from './pages/Dashboard';
import { Loan } from './pages/Loan';
import { MemberAccount } from './pages/MemberAccount';
import { PersonalData } from './pages/PersonalData';
import { Shares } from './pages/Shares';
import { Error } from './pages/error/Error';
import { NotMemberError } from './pages/error/NotMemberError';
import DataService from './services/DataService';
import { ServiceUnavailableError } from './pages/error/ServiceUnavailableError';

const theme = createTheme({
  palette: {
    primary: {
      main: '#64a70b',
    },
    secondary: {
      main: '#5b4261',
    },
  },
  typography: {
    fontFamily: "Source Sans Pro,sans-serif"
  }
});

const currentSubdomain = window.location.hostname.split('.')[0];
console.log("CurrentSubdomain: ", currentSubdomain);
const isAppSubdomain = currentSubdomain === 'app';

//Config object recieved from server
declare global {
  type Config = {
    cooperativeName: string,
    nominalShareValue: number,
    maxAllowedHeldShares: number,
    sharePurchaseFeeRate: number,
    bank_owner: string,
    bank_iban: string,
    bank_name: string,
    bank_bic: string,
    creditorIdentificationNumber: string,
    statuteUrl: string,
    email: string,
    address_street: string,
    address_extra: string,
    address_postalcode: string,
    address_city: string,
    address_country: string,
    dataProtectionRegulationUrl: string
  }
}

function App() {
  //Mobile-Variable
  const [isMobile, setMobile] = useState<boolean>(false);
  //Set default values so undefined error is prevented, there is probably a better solution to do this
  const [config, setConfig] = useState<Config>({
    cooperativeName: "Bürgerenergie",
    nominalShareValue: 1,
    maxAllowedHeldShares: 1,
    sharePurchaseFeeRate: 1,
    bank_owner: "",
    bank_iban: "",
    bank_name: "",
    bank_bic: "",
    creditorIdentificationNumber: "",
    statuteUrl: "",
    email: "",
    address_street: "",
    address_extra: "",
    address_postalcode: "",
    address_city: "",
    address_country: "",
    dataProtectionRegulationUrl: ""
  });

  function handleWindowSizeChange() {
    setMobile(window.innerWidth < 1280)
  }

  useEffect(() => {
    //Send alert if site key is not set (can happen very easily)
    if (process.env.6LePngwqAAAAADAED5ABwcdS2sTesTx9lFFXnJ-C === undefined) {
      alert("ERROR: Message for admin: The google recaptcha site key is not set. In order to send member applications you need to set the site key. (Look at dockerfile)")
    }

    //Request to fetch configurable data from backend service (backend jmix portal)
    DataService.getConfig(setConfig);

    //Needed for beg dachauerland to set proper iframe size
    function sendSize() {
      window.parent.postMessage({
        height: document.body.scrollHeight + 90,
        width: document.body.scrollWidth,
        type: 'iframeSize'
      }, 'https://buergerenergie-dachauerland.de');
    }

    sendSize();
    window.addEventListener('resize', sendSize);

    //Sets isMobile on resize
    handleWindowSizeChange();
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, [])


  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          {/*Routes with Navigation */}
          <Route element={<>
            <Navigation isMobile={isMobile} config={config!} />
            <Outlet />
          </>}>
            {isAppSubdomain ? (<></>) : (<Route path='/' element={<Dashboard isMobile={isMobile} config={config!} />} />)}
            <Route path='/personaldata' element={<PersonalData isMobile={isMobile} config={config!} />} />
            <Route path='/memberaccount' element={<MemberAccount isMobile={isMobile} config={config!} />} />
            <Route path='/shares' element={<Shares isMobile={isMobile} config={config!} />} />
            <Route path='/projectloans' element={<Loan isMobile={isMobile} config={config!} />} />
            <Route path='/attachments' element={<Attachments isMobile={isMobile} config={config!} />} />
            {/**Errors */}
            <Route path='/notmembererror' element={<NotMemberError config={config!} />} />
            <Route path='/forbidden' element={<Error error={403} />} />
            <Route path='/*' element={<Error error={404} />} />
          </Route>
          {/*Routes without Navigation */}
          {isAppSubdomain ? (<Route path='/' element={<Antrag isMobile={isMobile} config={config!} />} />) : (<Route path='/mitgliedsantrag' element={<Antrag isMobile={isMobile} config={config!} />} />)}
          <Route path='/serviceunavailable/:path' element={<ServiceUnavailableError isMobile={isMobile}/>} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
