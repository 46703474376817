import { Button, Link, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HeaderTable } from "../components/HeaderTable";
import { Loading } from "../components/Loading";
import { TitleTable } from "../components/TitleTable";
import AuthService from "../services/AuthService";
import DataService from "../services/DataService";


interface DashboardProps {
    isMobile: boolean
    config: Config
}
declare global {
    type BasicContact = {
        memberNumber: string,
        address_city: string,
        address_postalcode: string,
        address_street: string,
        firstname: string,
        lastname: string,
        primaryEmail: string,
    }
    type Project = {
        name: string,
        website: string,
        state: string
    }
}

export const Dashboard: React.FC<DashboardProps> = (props) => {
    const navigate = useNavigate();
    const [finishedLoading, setFinishedLoading] = useState<{ basicContact: boolean, projects: boolean }>({ basicContact: false, projects: false });
    const [basicContactData, setBasicContactData] = useState<BasicContact>({
        memberNumber: "",
        address_city: "",
        address_postalcode: "",
        address_street: "",
        firstname: "",
        lastname: "",
        primaryEmail: "",
    });
    const [projectData, setProjectData] = useState<Project[]>([{
        name: "Nicht vorhanden",
        website: "#",
        state: "Unbekannt"
    }]);

    useEffect(() => {
        if (!AuthService.isLoggedIn()) {
            AuthService.doLogin();
        } else {
            DataService.performRequest(`/rest/services/beg_PortalContactService/getBasicContact`, finishedLoading, setFinishedLoading, setBasicContactData, "basicContact", navigate)
            DataService.performRequest(`/rest/services/beg_PortalProjectService/getProjects`, finishedLoading, setFinishedLoading, setProjectData, "projects", navigate)
        }
    }, []);

    const to2dProjectsArray = (objectArray: any) => {
        let returnElement: any[] = [];
        objectArray.map((objectEl: Project) => {
            returnElement.push([
                <Link onClick={() => window.open(`${objectEl.website}`, '_blank')}>{objectEl.name}</Link>,
                getProjectState(objectEl.state)
            ]);
        })
        return returnElement;
    }

    const getProjectState = (state: string) => {
        if (state === "CANCELLED") return "Eingestellt"
        else if (state === "CONSTRUCTION") return "Im Bau"
        else if (state === "FINANCING") return "Finanzierungsphase"
        else if (state === "OPERATIONAL") return "In Betrieb"
        else if (state === "PLANNING") return "In Planung"
        else return "Unbekannt"
    }

    if (!finishedLoading.basicContact && !finishedLoading.projects) {
        return <Loading />
    }
    return (
        <div className="content">
            <Typography variant="h6">Dashboard</Typography>
            <div className="tableDiv" style={{ width: "50%", display: "inline-block" }}>
                <Typography variant="subtitle1">Persönliche Daten</Typography>
                <TitleTable title="Kontaktdaten" rows={[
                    { name: "Mitgliedsnummer", value: basicContactData.memberNumber },
                    { name: "Vorname", value: basicContactData.firstname },
                    { name: "Nachname", value: basicContactData.lastname },
                    { name: "Straße", value: basicContactData.address_street },
                    { name: "Postleitzahl", value: basicContactData.address_postalcode },
                    { name: "Ort", value: basicContactData.address_city },
                    { name: "E-Mail-Adresse", value: basicContactData.primaryEmail },
                ]} />
                <Button fullWidth variant="outlined" onClick={() => navigate('/personaldata')}>Mehr</Button>
            </div>
            <div className="tableDiv" style={{ width: "50%", display: "inline-block", verticalAlign: "top" }}>
                <Typography variant="subtitle1">Aktuelle Projekte</Typography>
                <HeaderTable headerRows={["Name", "Status"]}
                    rows={
                        to2dProjectsArray(projectData)
                    }
                />
            </div>
        </div>
    )

}
