

interface ErrorProps {
    error: number
}

export const Error: React.FC<ErrorProps> = (props) => {
    return (
        <>
            <div>Fehler {props.error}</div>
        </>
    )
}
