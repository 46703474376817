
export const validationService: {[index: string]: Function;} = {
    alphabet: (value: string) => {
        const regex = /^[^0-9,!"§$%&/()<>=?\\]+$/;
        return regex.test(value)
    },
    number: (value: number) => {
        return true;
    },
    street: (value: string) => {
        const regex = /^([\S\s]+?)\s+([\d-\s]*?)\s*([\w])?$/;
        return regex.test(value)
    },
    postalcode: (value: string) => {
        const regex = /^[0-9]{5}$/;
        return regex.test(value)
    },
    phone: (value: string) => {
        const regex = /^\+?[0-9 /-][0-9 /-]{7,20}$/;
        return regex.test(value)
    },
    email: (value: string) => {
        const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,10}$/;
        return regex.test(value)
    },
    taxnumber: (value: string) => {
        //Deutsche Steueridentifikationsnummer
        const regex = /^[0-9]{11}$/;
        if (!regex.test(value)) return false;
        const arr = value.split("");
        let product: number = 10;
        for (let i = 0; i<10; i++) {
            let sum = (parseInt(arr[i]) + product) % 10;
            if (sum == 0) sum = 10;
            product = (sum * 2) % 11;
        }
        let prüfziffer = 11 - product;
        if (prüfziffer == 10) prüfziffer = 0;
        if (parseInt(arr[10]) === prüfziffer) return true;
        else return false;
    },
    date: (value: string) => {
        const regex = /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
        return regex.test(value)
    },
    iban: (value: string) => {
        const regex = /^[A-Z]{2}(?:[ ]?[0-9]){18,20}$/;
        return regex.test(value)
    },
    bic: (value: string) => {
        const regex = /^([a-zA-Z]{4}[a-zA-Z]{2}[a-zA-Z0-9]{2}([a-zA-Z0-9]{3})?)$/;
        return regex.test(value)
    },
    nocheck: (value: any) => {
        return true;
    }
}
