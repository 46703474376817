import { Link, Typography } from "@mui/material"
import AuthService from "../services/AuthService"
import styles from '../styles/Navigation.module.css';
import { useNavigate } from "react-router-dom";


interface NavigationProps {
    isMobile: boolean
    config: Config
}

export const Navigation: React.FC<NavigationProps> = (props) => {
    const navigate = useNavigate();

    return (
        <div>
            <Typography className={styles.navigationTitle} variant="h6">{props.config.cooperativeName}</Typography>
            <div className={styles.navigationDiv}>
                <Link className={styles.navigationLink} onClick={() => navigate('/')}>Dashboard</Link>
                <Link className={styles.navigationLink} onClick={() => navigate('/personaldata')}>Persönliche Daten</Link>
                <Link className={styles.navigationLink} onClick={() => navigate('/memberaccount')}>Mitgliedskonto</Link>
                <Link className={styles.navigationLink} onClick={() => navigate('/shares')}>Genossenschaftsanteile</Link>
                <Link className={styles.navigationLink} onClick={() => navigate('/projectloans')}>Projektdarlehen</Link>
                <Link className={styles.navigationLink} onClick={() => navigate('/attachments')}>Dokumente</Link>
                <Link className={styles.navigationLink} onClick={() => {AuthService.doLogout()}} >Abmelden ({AuthService.getUsername()})</Link>
            </div>
        </div>
    )
}
