import { useEffect, useState } from "react";
import AuthService from "../services/AuthService";
import { TitleTable } from "../components/TitleTable";
import axios from "axios";
import { Loading } from "../components/Loading";
import { Typography } from "@mui/material";
import DataService from "../services/DataService";
import { useNavigate } from "react-router-dom";


interface PersonalDataProps {
    isMobile: boolean
    config: Config
}
declare global {
    type Contact = {
        address_city: string,
        address_extra: string,
        address_postalcode: string,
        address_street: string,
        birthdate: string,
        birthplace: string,
        firstname: string,
        isMember: boolean,
        lastname: string,
        primaryEmail: string,
        salutation: string,
        title: string
    }
    type Bank_Account = {
        name: string,
        iban: string,
        accountnr: string,
        isDebit: boolean,
        bank: {bic:string,name:string}
    }
}


export const PersonalData: React.FC<PersonalDataProps> = (props) => {
    const navigate = useNavigate();
    const [finishedLoading, setFinishedLoading] = useState<{contact: boolean, bank: boolean}>({contact: false, bank: false});
    const [contactData, setContactData] = useState<Contact>({
        address_city: "",
        address_extra: "",
        address_postalcode: "",
        address_street: "",
        birthdate: "",
        birthplace: "",
        firstname: "",
        isMember: false,
        lastname: "",
        primaryEmail: "",
        salutation: "",
        title: ""
    });
    const [bankData, setBankData] = useState<Bank_Account>({
        name: "Nicht vorhanden",
        iban: "Nicht vorhanden",
        accountnr: "Nicht vorhanden",
        isDebit: false,
        bank:{bic:"Nicht vorhanden",name:"Nicht vorhanden"}
    });

    useEffect(() => {
        if (!AuthService.isLoggedIn()) {
            AuthService.doLogin();
        } else {
            DataService.performRequest("/rest/services/beg_PortalContactService/getContact",finishedLoading,setFinishedLoading,setContactData,"contact", navigate)
            DataService.performRequest("/rest/services/beg_PortalBank_AccountsService/getBankAccount",finishedLoading,setFinishedLoading,setBankData,"bank", navigate)
        }
    }, []);

    if (!finishedLoading.bank || !finishedLoading.contact) {
        return <Loading />
    }

    return (
        <div className="content">
            <Typography variant="h6">Persönliche Daten</Typography>
            <div className="tableDiv" style={{ width: "50%", display: "inline-block" }}>
                <TitleTable title="Kontaktdaten" rows={[
                    { name: "Mitgliedsnummer", value: "-" },
                    { name: "Anrede", value: contactData.salutation },
                    { name: "Titel", value: contactData.title },
                    { name: "Vorname", value: contactData.firstname },
                    { name: "Nachname", value: contactData.lastname },
                    { name: "Geburtsname", value: "-" },
                    { name: "Geburtsdatum", value: new Date(contactData.birthdate).toLocaleDateString() },
                    { name: "Geburtsort", value: contactData.birthplace },
                    { name: "Steuer-ID", value: "-" },
                    { name: "Konfession", value: "-" },
                    { name: "Straße", value: contactData.address_street },
                    { name: "Adresszusatz", value: contactData.address_extra },
                    { name: "Postleitzahl", value: contactData.address_postalcode },
                    { name: "Ort", value: contactData.address_city },
                    { name: "Telefon", value: "-" },
                    { name: "E-Mail-Adresse", value: contactData.primaryEmail },
                ]} />
            </div>
            <div className="tableDiv" style={{ width: "50%", display: "inline-block", verticalAlign: "top" }}>
                <TitleTable title="1. Bankverbindung" rows={[
                    { name: "Kontoinhaber", value: bankData.name },
                    { name: "Kontonummer", value: bankData.accountnr },
                    { name: "IBAN", value: bankData.iban },
                    { name: "BIC", value: bankData.bank.bic },
                    { name: "Name der Bank", value: bankData.bank.name },
                    { name: "Einzugsermächtigung", value: bankData.isDebit ? "Ja" : "Nein" },
                ]} />
            </div>
        </div>
    )
}
