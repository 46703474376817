import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import AuthService from "../services/AuthService"
import styles from '../styles/Table.module.css';


interface HeaderTableProps {
    headerRows: string[],
    //Matrix der Tabelle
    rows: string[][]
}

//Can be replaced with Material UI table

export const HeaderTable: React.FC<HeaderTableProps> = (props) => {
    let colorChange = false;
    return (
        <table className={styles.titletable}><tbody>
            <tr>
                {props.headerRows.map((headerRow: any) => <th key={Math.random()} className={styles.titletableTitle}>{headerRow}</th>)}
            </tr>
            {props.rows.map((row: any) => {
                colorChange = !colorChange;
                return <tr key={Math.random()}>
                    {row.map((cell: any) => 
                        <td key={Math.random()} className={styles.titletableCell} style={{ backgroundColor: colorChange ? "#F8F8F8" : "#eaf4e5" }}>{cell}</td>
                    )}
                </tr>
            })}
        </tbody></table>
    )
}
