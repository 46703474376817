import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HeaderTable } from "../components/HeaderTable";
import { Loading } from "../components/Loading";
import AuthService from "../services/AuthService";
import DataService from "../services/DataService";


interface SharesProps {
    isMobile: boolean
    config: Config
}

declare global {
    type Share = {
        name: string,
        nominalValue: number,
        quantity: number,
        signedDate: string,
        acceptedDate: string,
        payedDate: string
    }
}

export const Shares: React.FC<SharesProps> = (props) => {
    const navigate = useNavigate();
    const [finishedLoading, setFinishedLoading] = useState<{ shares: boolean }>({ shares: false });

    const [sharesData, setSharesData] = useState<Share[]>([{
        name: "Nicht vorhanden",
        nominalValue: 0.00,
        quantity: 0,
        signedDate: "Nicht vorhanden",
        acceptedDate: "Nicht vorhanden",
        payedDate: "Nicht vorhanden"
    }]);

    useEffect(() => {
        if (!AuthService.isLoggedIn()) {
            AuthService.doLogin();
        } else {
            DataService.performRequest(`/rest/services/beg_PortalShareService/getShares`, finishedLoading, setFinishedLoading, setSharesData, "shares", navigate)
        }
    }, []);


    if (!finishedLoading.shares) {
        return <Loading />
    }

    const to2dArray = (objectArray: any) => {
        let returnElement: any[] = [];
        objectArray.forEach((objectEl: Share) => {
            returnElement.push([
                objectEl.quantity,
                objectEl.nominalValue.toLocaleString(undefined, { minimumFractionDigits: 2 }) + " €",
                objectEl.signedDate ? new Date(objectEl.signedDate).toLocaleDateString() : "",
                objectEl.acceptedDate ? new Date(objectEl.acceptedDate).toLocaleDateString() : "",
                objectEl.payedDate ? new Date(objectEl.payedDate).toLocaleDateString() : ""
            ]);
        })
        return returnElement;
    }

    const getSum = () => {
        let sum = 0;
        sharesData.forEach((e) => {
            if (e.payedDate) {
                sum += e.nominalValue * e.quantity;
            }
        })
        return sum;
    }
    const getQuantity = () => {
        let quantity = 0;
        sharesData.forEach((e) => {
            if (e.payedDate) {
                quantity += e.quantity;
            }
        })
        return quantity;
    }

    return (
        <div className="content">
            <Typography variant="h6">Genossenschaftsanteile</Typography>
            <p>Sie besitzen {getQuantity()} Anteil{getQuantity() !== 1 && 'e'} im Gesamtwert von {getSum().toLocaleString(undefined, { minimumFractionDigits: 2 })} €.</p>
            <Typography variant="caption">Anteile werden erst als "Im Besitz" gezählt, wenn eine Zahlung festgestellt wurde</Typography>
            <HeaderTable headerRows={["Anzahl", "Nennwert", "Unterzeichnet am", "Angenommen am", "Ein-/Auszahlung am"]}
                rows={
                    to2dArray(sharesData)
                }
            />
        </div>
    )


}